<!--科技竞赛以及科技政策-->
<template>
  <div class="keji">
    <div class="xuqiu_toutiao" style="height: 425px">
      <div class="xuqiu">
        <div class="xuqiu_header">
          <div class="tixing_xuqiu_parent">
            <div class="tixing" style="width: auto;padding-right: 30px">
              科技竞赛
            </div>
          </div>
        </div>
        <a v-if="data.picUrl" :href="data && data.picUrl">
          <img class="keji_content_left" :src="data&&data.pic"/>
        </a>
        <img v-else class="keji_content_left" :src="data&&data.pic"/>
      </div>
      <div class="toutiao">
        <div class="toutiao_header">
          <div class="tixing_xuqiu_parent">
            <div class="tixing" style="width: auto;padding-right: 30px">
              {{data.name}}
            </div>
            <span style="float: right;display: inline-block;cursor: pointer" @click="goSciencePolicy">更多>></span>
          </div>
        </div>
        <div class="keji_content_right">
          <div class="keji_content_right_per"
               v-for="item in data&&data.labels[0].objs.length>7?data.labels[0].objs.slice(0,7):data.labels[0].objs"
               :key="item.id">
            <router-link :to="{path:'/home/sciencePolicyDetail',query: {id:item.id}}" target="_blank" style="color: #323232;" >
              <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="item.name">{{item.name}}</p>
            </router-link>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'raceAndPolicy',
    props: {
      data: Object
    },
    mounted() {
      console.log(this.data)
      },
    data() {
      return {}
    },
    methods: {
      goSciencePolicy() {
        this.$router.push({
          path: '/home/policy',
        })
      },
    }
  }
</script>

<style>
  .keji_content_right_per {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "Microsoft YaHei", serif;
    color: rgb(50, 50, 50);
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 20px;
  }

  .keji_content_right_per:last-child {
    border: none;
  }

  .keji_content_right {
    height: 350px;
    width: 480px;
    border: 1px solid #f5f5f5;
  }

  .keji_content_left {
    height: 350px;
    width: 700px;
    border: 1px solid #f5f5f5;
  }

  .keji {
    width: 1200px;
    margin: 0 auto;
  }

  .keji_content_right > div:hover {
    box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  }
</style>
